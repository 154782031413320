<template>
  <table :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th>Verkoper</th>
      <th class="table__data--right">Aantal</th>
      <th class="table__data--right">Stadagen</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">Bruto</th>
    </thead>
    <tbody>
      <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
        <td>{{ item.naam }}</td>
        <td class="table__data--right">{{ item.aantal }}</td>
        <td class="table__data--right">{{ item.stadagen }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ item.verkopen }}</td>
      </tr>
    </tbody >
    <tfoot>
      <th>Totaal: </th>
      <th class="table__data--right">{{ table_data_sum.aantal }}</th>
      <th class="table__data--right">{{ table_data_sum.stadagen }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ table_data_sum.verkopen }}</th>
    </tfoot>
  </table>
</template>

<script>


export default {
  props: {
    table_data: [Array, Object],
    table_data_sum: [Array, Object],
    table_style: String,
  },

};
</script>