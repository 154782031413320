<template>
  <table :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th>Taskforce</th>
      <th class="table__data--right">Aantal</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">Netto</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">Factuur</th>
    </thead>
    <tbody>
      <tr v-for="(taskforce_data, key) in table_data" :key="key" :class="`table__row--color`">
        <td>{{ taskforce_data.tasknaam }}</td>
        <td class="table__data--right">{{ taskforce_data.aantal }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ taskforce_data.nettoprijs }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ taskforce_data.factuurprijs }}</td>
      </tr>
    </tbody>
    <tfoot>
      <th></th>
      <th class="table__data--right">{{ table_data_sum.amount }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ table_data_sum.netto }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ table_data_sum.factuur }}</th>
    </tfoot>
  </table>
</template>
<script>


export default {
  props: {
    table_data: Array,
    table_data_sum: Object,
    table_header: Array,
    table_style: String,
  },

};
</script>
