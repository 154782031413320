<template>
  <div class="_container">
    <div class="_text-center p2">
      <h2>Verkopen per week</h2>
      <span @click="previousYear"><i class="fas fa-arrow-left"/></span>
      <span class="m2">Jaar: {{ year_number }}</span>
      <span @click="nextYear"><i class="fas fa-arrow-right"/></span>
      <span class="m2" />
      <span @click="previousWeek"><i class="fas fa-arrow-left"/></span>
      <span class="m2">Week: {{ week_number }}</span>
      <span @click="nextWeek"
        ><i @click="nextWeek" class="fas fa-arrow-right"
      /></span>
    </div>
    <Loading v-if="loading" />
    <div v-if="!loading">
      <Tabs>
        <Tab title="Verkopen per week" active>
          <div class="flexbox spaceevenly">
            <DaysInStock :data="this.daysinstock_trucks" styling="200" />
            <DaysInStock :data="this.daysinstock_sum" styling="sum" />
            <DaysInStock :data="this.daysinstock_vans" styling="210" />
          </div>
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <h3 class="_text-center p2">Kleyn Trucks</h3>
              <SalesPerWeekTable
                :table_data="this.total_sales_trucks"
                :table_data_sum="this.total_sum_trucks"
                table_style="200"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Kleyn Trailers</h3>
              <SalesPerWeekTable
                :table_data="this.total_sales_trailers"
                :table_data_sum="this.total_sum_trailers"
                table_style="200-trailer"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Kleyn Vans</h3>
              <SalesPerWeekTable
                :table_data="this.total_sales_vans"
                :table_data_sum="this.total_sum_vans"
                table_style="210"
              />
            </div>
          </div>
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <h3 class="_text-center p2">Trucks & Trailers Afleveringen</h3>
              <DeliveriesPerWeekTable
                :table_data="this.deliveries_trucks"
                :table_data_sum="this.deliveries_total_trucks"
                table_style="200"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Vans Afleveringen</h3>
              <DeliveriesPerWeekTable
                :table_data="this.deliveries_vans"
                :table_data_sum="this.deliveries_total_vans"
                table_style="210"
              />
            </div>
          </div>
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <h3 class="_text-center p2">Trucks & Trailers vervallen</h3>
              <CancelledTable
                :table_data="this.cancelled_trucks"
                :table_data_sum="this.total_cancelled_trucks"
                table_style="200"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Vans vervallen</h3>
              <CancelledTable
                :table_data="this.cancelled_vans"
                :table_data_sum="this.total_cancelled_vans"
                table_style="210"
              />
            </div>
          </div>
        </Tab>
        <Tab
          title="Landen"
          v-if="
            checkroles([
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'marketing',
              'inkoper',
              'management',
              'binnenkomst'
            ], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
          "
        >
          <b-card-text>
            <div class="flexbox spaceevenly">
              <div class="p2 overflow">
                <h3 class="_text-center p2">Landen Trucks</h3>
                <CountrySalesPerWeekTable
                  :table_data="this.country_sales_trucks"
                  :table_data_sum="this.country_total_trucks"
                  table_style="200"
                />
              </div>
              <div class="p2 overflow">
                <h3 class="_text-center p2">Landen Vans</h3>
                <CountrySalesPerWeekTable
                  :table_data="this.country_sales_vans"
                  :table_data_sum="this.country_total_vans"
                  table_style="210"
                />
              </div>
            </div>
          </b-card-text>
        </Tab>
        <Tab
          title="Taskforce"
          v-if="
            checkroles([
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'marketing',
              'inkoper',
              'management',
              'binnenkomst'
            ], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
          "
        >
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <h3 class="_text-center p2">Taskforce Trucks</h3>
              <TaskforcePerWeekTable
                :table_data="this.taskforce_trucks"
                :table_data_sum="this.taskforce_total_trucks"
                table_style="200"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Taskforce Vans</h3>
              <TaskforcePerWeekTable
                :table_data="this.taskforce_vans"
                :table_data_sum="this.taskforce_total_vans"
                table_style="210"
              />
            </div>
          </div>
        </Tab>
        <Tab
          title="Verkopen per inkoper"
          v-if="
            checkroles([
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'marketing',
              'inkoper',
              'management',
            ], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
          "
        >
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <h3 class="_text-center p2">Kleyn Trucks</h3>
              <SalesPerWeekTable
                :table_data="sales_trucks_per_buyer"
                :table_data_sum="total_sum_trucks_per_buyer"
                table_style="200"
                table_type="inkoper"
              />
            </div>
            <div class="p2 overflow">
              <h3 class="_text-center p2">Kleyn Vans</h3>
              <SalesPerWeekTable
                :table_data="sales_vans_per_buyer"
                :table_data_sum="total_sum_vans_per_buyer"
                table_style="210"
                table_type="inkoper"
              />
            </div>
          </div>
        </Tab>
        <Tab
          title="Totaal trucks"
          v-if="
            checkroles([
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'marketing',
              'inkoper',
              'management',
              'binnenkomst',
              'magazijn'
            ], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
          "
        >
          <div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <SalesPerWeekItemTable
                :table_data="sales_items_trucks"
                table_style="200"
                :year="year_number"
                :week="week_number"
              />
            </div>
          </div>
        </Tab>
        <Tab
          title="Totaal vans"
          v-if="
            checkroles([
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'marketing',
              'inkoper',
              'management',
              'binnenkomst',
              'magazijn'
            ], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
          "
          ><div class="flexbox spaceevenly">
            <div class="p2 overflow">
              <SalesPerWeekItemTable
                :table_data="sales_items_vans"
                table_style="210"
                :year="year_number"
                :week="week_number"
              />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import SalesPerWeekTable from "@/components/SalesPerWeekTable.vue";
import DaysInStock from "@/components/DaysInStock.vue";
import CancelledTable from "@/components/CancelledTable.vue";
import DeliveriesPerWeekTable from "@/components/DeliveriesPerWeekTable.vue";
import CountrySalesPerWeekTable from "@/components/CountrySalesPerWeekTable.vue";
import TaskforcePerWeekTable from "@/components/TaskforcePerWeekTable.vue";
import moment from "moment";
import Loading from "@/components/Loading";
import SalesPerWeekItemTable from "@/components/SalesPerWeekItemTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

import {
  subtractTime,
  addTime,
  getWeekNumber,
  getYearNumber,
} from "@/functions/date.js";

export default {
  props: {
    data: String,
  },
  components: {
    SalesPerWeekTable,
    DaysInStock,
    CancelledTable,
    CountrySalesPerWeekTable,
    TaskforcePerWeekTable,
    DeliveriesPerWeekTable,
    SalesPerWeekItemTable,
    Tabs,
    Tab,
    Loading,
  },
  data: () => ({
    loading: true,

    total_sales_trucks: null,
    total_sales_vans: null,
    total_sales_trailers: null,
    total_sum_trucks: null,
    total_sum_vans: null,
    total_sum_trailers: null,

    daysinstock_trucks: null,
    daysinstock_vans: null,
    daysinstock_sum: null,

    sales_items_trucks: null,
    sales_items_vans: null,

    total_cancelled_trucks: null,
    total_cancelled_vans: null,
    cancelled_trucks: null,
    cancelled_vans: null,

    country_sales_trucks: null,
    country_sales_vans: null,
    country_total_trucks: null,
    country_total_vans: null,

    taskforce_trucks: null,
    taskforce_vans: null,
    taskforce_total_trucks: null,
    taskforce_total_vans: null,

    sales_trucks_per_buyer: null,
    sales_vans_per_buyer: null,
    total_sum_vans_per_buyer: null,
    total_sum_trucks_per_buyer: null,

    deliveries_trucks: null,
    deliveries_vans: null,
    deliveries_total_trucks: null,
    deliveries_total_vans: null,

    week_number: null,
    year_number: null,
    full_date: null,
  }),
  created() {
    this.full_date = moment();
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.week_number = getWeekNumber(this.full_date);
      this.year_number = getYearNumber(this.full_date);
      //loads all data from sales per week when page is created/rendered
      const sales_url = `sales-per-week/${this.year_number}/${this.week_number}`;
      const cancelled_url = `cancelled-per-week/${this.year_number}/${this.week_number}`;
      const country_sales_url = `country-per-week/${this.year_number}/${this.week_number}`;
      const taskforce_sales_url = `taskforce-per-week/${this.year_number}/${this.week_number}`;
      const deliveries_url = `deliveries-per-week/${this.year_number}/${this.week_number}`;

      request(sales_url, "GET").then(
        ({
          sales_data,
          days_in_stock,
          sales_items,
          sales_per_buyer,
          error,
        }) => {
          if (error) this.error = error;
          this.total_sales_trucks = sales_data.sales_trucks;
          this.total_sales_vans = sales_data.sales_vans;
          this.total_sales_trailers = sales_data.sales_trailers;
          this.total_sum_trucks = sales_data.total_sum_trucks;
          this.total_sum_vans = sales_data.total_sum_vans;
          this.total_sum_trailers = sales_data.total_sum_trailers;
          //dayinstock table data
          this.daysinstock_trucks = days_in_stock.days_in_stock_trucks;
          this.daysinstock_vans = days_in_stock.days_in_stock_vans;
          this.daysinstock_sum = days_in_stock.days_in_stock_sum;

          const { sales_items_vans, sales_items_trucks } = sales_items;
          this.sales_items_trucks = sales_items_trucks;
          this.sales_items_vans = sales_items_vans;

          const {
            sales_trucks_per_buyer,
            sales_vans_per_buyer,
            total_sum_vans_per_buyer,
            total_sum_trucks_per_buyer,
          } = sales_per_buyer;
          this.sales_trucks_per_buyer = sales_trucks_per_buyer;
          this.sales_vans_per_buyer = sales_vans_per_buyer;
          this.total_sum_trucks_per_buyer = total_sum_trucks_per_buyer;
          this.total_sum_vans_per_buyer = total_sum_vans_per_buyer;
        }
      );

      request(cancelled_url, "GET").then(({ cancelled_per_week }) => {
        //vervallen table data
        this.cancelled_trucks = cancelled_per_week.cancelled_trucks;
        this.cancelled_vans = cancelled_per_week.cancelled_vans;
        this.total_cancelled_vans = cancelled_per_week.total_cancelled_vans;
        this.total_cancelled_trucks = cancelled_per_week.total_cancelled_trucks;
      });

      request(country_sales_url, "GET").then(({ country_data }) => {
        //country sales data
        this.country_sales_trucks = country_data.country_sales_trucks;
        this.country_sales_vans = country_data.country_sales_vans;
        this.country_total_trucks = country_data.country_total_trucks;
        this.country_total_vans = country_data.country_total_vans;
      });

      request(taskforce_sales_url, "GET").then(({ taskforce_sales }) => {
        this.taskforce_trucks = taskforce_sales.taskforce_trucks;
        this.taskforce_vans = taskforce_sales.taskforce_vans;
        this.taskforce_total_trucks = taskforce_sales.taskforce_total_trucks;
        this.taskforce_total_vans = taskforce_sales.taskforce_total_vans;
      });

      request(deliveries_url, "GET").then(
        ({
          deliveries_trucks,
          deliveries_vans,
          deliveries_total_trucks,
          deliveries_total_vans,
        }) => {
          this.deliveries_trucks = deliveries_trucks;
          this.deliveries_vans = deliveries_vans;
          this.deliveries_total_trucks = deliveries_total_trucks;
          this.deliveries_total_vans = deliveries_total_vans;
          this.loading = false;
        }
      );
    },

    previousWeek() {
      this.full_date = subtractTime(1, this.full_date, "weeks");
      this.getData();
    },

    nextWeek() {
      this.full_date = addTime(1, this.full_date, "weeks");
      this.getData();
    },

    previousYear() {
      this.full_date = subtractTime(1, this.full_date, "years");
      this.getData();
    },

    nextYear() {
      this.full_date = addTime(1, this.full_date, "years");
      this.getData();
    },
  },
};
</script>
