<template>
  <table :class="`table__border--${table_style}`" v-if="table_data">
    <thead :class="`table__head--${table_style}`">
      <th>Vlag</th>
      <th>Land</th>
      <th>Aantal</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Netto</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Factuur</th>
    </thead>
    <tbody>
      <tr v-for="(country_data, key) in table_data" :key="key" :class="`table__row--color`">
        <td><Flag v-if="country_data.landcode.toUpperCase() != 'GB'" :code="country_data.landcode.toUpperCase()" /></td>
        <td>{{ country_data.land }}</td>
        <td>{{ country_data.aantal }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€ {{ country_data.nettoprijs }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€ {{ country_data.factuurprijs }}</td>
      </tr>
    </tbody>
    <tfoot>
      <th>Totaal:</th>
      <th />
      <th>{{ table_data_sum.amount }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€ {{ table_data_sum.netto }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€ {{ table_data_sum.factuur }}</th>
    </tfoot>
  </table>
</template>

<script>


export default {
  props: {
    table_data: Array,
    table_data_sum: Object,
    table_style: String,
  },

};
</script>
