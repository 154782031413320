<template>
  <div class="flexbox p2">
    <table :class="`card__border--${styling} card__table`">
      <thead :class="`card__head--${styling}`">
        <img :src="require(`@/assets/kleyn_logos/logo_${styling}.svg`)" class="card__image" alt="">
        <td class="card__cell card__head--text">Totaal</td>
        <td class="card__cell card__head--text">&lt;30</td>
        <td class="card__cell card__head--text">>=30</td>
      </thead>
      <tfoot>
        <th class="card__cell">Aantal:</th>
        <th class="card__cell">{{ data.total }}</th>
        <td class="card__cell">{{ data.lessthen30 }}</td>
        <td class="card__cell">{{ data.morethen30 }}</td>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    styling: String,
  },
};
</script>
