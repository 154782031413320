<template>
  <table :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th v-if="table_type == 'inkoper'">Inkoper</th>
      <th v-else>Verkoper</th>
      <th>Aantal</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Netto</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Factuur</th>
      <th>Afschr</th>
    </thead>
    <tbody>
      <tr v-for="(weeksales, key) in table_data" :key="key">
        <td>{{ key }}</td>
        <td class="table__data--right">{{ weeksales.amount_of_sales }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ weeksales.total_sum_netto_price.replace(/,/g, '.') }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right">€ {{ weeksales.total_sum_price.replace(/,/g, '.') }}</td>
        <td v-if="weeksales.total_sum_writeoff > '0'" class="table__data--right">
          € {{ weeksales.total_sum_writeoff.replace(/,/g, '.') }}
        </td>
        <td v-else class="table__data--right">0</td>
      </tr>
    </tbody >
    <tfoot>
      <th>Totaal</th>
      <th class="table__data--right ">{{ table_data_sum.total_sum_sales }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right ">€ {{ table_data_sum.total_sum_netto_price.replace(/,/g, '.') }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right ">€ {{ table_data_sum.total_sum_price.replace(/,/g, '.') }}</th>
      <th class="table__data--right ">€ {{ table_data_sum.total_sum_writeoff.replace(/,/g, '.') }}</th>
    </tfoot>
  </table>
</template>

<script>


export default {
  props: {
    table_data: Object,
    table_data_sum: Object,
    table_style: String,
    table_type: String,
  },

};
</script>
