<template>
  <table :class="`table--perweek table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th>Naam</th>
      <th>Aantal</th>
    </thead>
    <tbody>
      <tr v-for="(value, name, key) in table_data" :key="key" :class="`table__row--color`">
        <td>{{ name }}</td>
        <td class="table__data--right">{{ value }}</td>
      </tr>
    </tbody>
    <tfoot>
      <th>Totaal:</th>
      <th class="table__data--right">{{ table_data_sum }}</th>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: {
    table_header: Array,
    table_data: [Object, Array],
    table_data_sum: Number,
    table_style: String,
  },
};
</script>
