<template>
  <div>
    <button v-if="checkroles(['management', 'it'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
    <table :class="`table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <th>Foto</th>
        <th>Item</th>
        <th v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Klant</th>
        <th>Inkoper</th>
        <th>Verkoper</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Inkopen</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Kosten</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Netto</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Factuur</th>
        <th style="min-width: 100px">Datum</th>
        <th>Lev</th>
        <th>Afschr</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Netto resultaat</th>
        <th>Stadagen</th>
      </thead>
      <tbody>
        <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
          <td>
            <img :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.itemno}/thumb`" />
          </td>
          <td>
            <ImageHover :itemnummer="item.itemno" :bu="item.bu" />
            {{ item.soortnaam }}<br />
            {{ item.opbouwnaam }}<br />
            {{ item.merktype }}<br />
            {{ item.regjaar }}
          </td>
          <td v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
            <router-link :to="{ name: 'klantpagina', params: { id: item.klant } }">
              {{ item.klant }}
            </router-link><br />
            {{ item.naam }}<br />
            {{ item.adres }}<br />
            {{ item.plaats }}<br />
            {{ item.land }}
          </td>
          <td>{{ item.inkopernaam }}</td>
          <td>{{ item.verkopernaam }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{
            item.inkoopbedrag }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{
            item.kostenbedrag }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{ item.nettoprijs
          }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{ item.prijs }}
          </td>
          <td>{{ item.datum }}</td>
          <td>{{ item.leveringsvoorwaarde }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{
            item.afschrijvingverkoop }}</td>
          <td class="table__data--right"
            v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">€{{
              item.nettoresultaat }}</td>
          <td class="table__data--right">{{ item.stadagen }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ImageHover from "@/components/ImageHover.vue";
import * as XLSX from "xlsx";

export default {
  props: {
    table_data: Array,
    table_style: String,
    year: [String, Number],
    week: [String, Number]
  },
  components: { ImageHover },
  methods: {
    createExcel() {
      let sales_orders = [];
      this.table_data.forEach((i) => {
        sales_orders.push({
          "Itemnummer": i.itemno,
          "Soort": i.soortnaam,
          "Opbouw": i.opbouwnaam,
          "Merk": i.merktype,
          "reg. jaar": i.regjaar,
          "Klant": i.klant,
          "Naam": i.naam,
          "Adres": i.adres,
          "Stad": i.stad,
          "Land": i.land,
          "Inkoper": i.inkopernaam,
          "Verkoper": i.verkopernaam,
          "Inkoopbedrag": i.inkoopbedrag,
          "Kosten": i.kostenbedrag,
          "Netto": i.nettoprijs,
          "Prijs": i.prijs,
          "Datum": i.datum,
          "Leveringsvoorwaarde": i.leveringsvoorwaarde,
          "Afschrijving": i.afschrijvingverkoop,
          "Netto resultaat": i.nettoresultaat,
          "Stadagen": i.stadagen
        });
      });

      var telWS = XLSX.utils.json_to_sheet(sales_orders);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `${this.person_data}_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `Verkochte_items_${this.week}_${this.year}.xlsx`
      );
    },
  },

};
</script>
